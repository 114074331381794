@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap);

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
  overflow-x: hidden;
}
.text-muted a {
  color: #fff;
}
a {
  text-decoration: none;
  background-color: transparent;
}
a {
  scroll-behavior: smooth;
  color: #4980be;
  font-weight: 500;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #fff;
  text-decoration: none;
  background-color: transparent;
}
p {
  font-size: 14px;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
  color: #6b6a6a;
  font-weight: 400;
  font-size: 14px;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0px;
  padding-bottom: 0px;
}
.navbar-brand {
  display: inline-block;
  padding-top: 15px;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 10%;
  margin-bottom: 0;
  list-style: none;
}
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: #333;
  font-weight: 600;
  /* border: 1px #dee2e6a3 solid; */
}
.homepage {
  position: relative;

  background-size: cover;
  height: 400px;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.homepage:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #134173b3;
}
.RegisterSec {
  background-color: #fff;
  height: 1200px;
}
.loginSec {
  margin-top: 10%;
  padding: 10%;
}
.registerSec {
  margin-top: 10%;
  padding: 10%;
}
.loginSubmit {
  width: 100%;
  padding: 5px 5px 5px 5px;
}
.loginSubmit:hover {
  width: 100%;
  padding: 5px 5px 5px 5px;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.marker-btn img {
  width: 20px;
  height: 20px;
}
.marker-btn {
  border: none;
  background: none;
}

.danger-alert {
  color: #721c24;
}
.Toastify__toast--default {
  color: #fafafa;
}
.mr-auto,
.mx-auto {
  float: right;
}
.bg-dark {
  background-color: #fff !important;
  color: #333;
  border-bottom: 1px #ced4da6e solid;
}
.navbar-dark .navbar-nav .nav-link {
  color: #6b6a6a;
  font-weight: 500;
}
.navbar-dark .navbar-nav .nav-link :hover {
  color: #333;
}
.logo {
  width: 40px;
}
.navbar-brand h3 {
  color: #394ca5;
  font-size: 18px;
}
.elite {
  font-weight: 400;
  cursor: pointer;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 3.5rem;
    padding-top: 20px;
    padding-left: 0.5rem;
  }
}
.navBorder {
  padding-top: 10px;
  border-bottom: 2px #6c757d24 solid;
}
.loginHead {
  margin-top: 100px;
  text-align: center;
}
.loginHead h2 {
  font-size: 50px;
  font-weight: bolder;
  margin-left: 2%;
}
.registerHead {
  margin-top: 100px;
  text-align: center;
}
.registerHead small {
  color: #333;
}
.registerHead h2 {
  font-size: 50px;
  font-weight: bolder;
}
.resetHead {
  margin-top: 150px;
  text-align: center;
}
.resetHead h2 {
  font-size: 50px;
  font-weight: bolder;
}
.loginSec {
  background-color: #fff;
  border: 1px #00000014 solid;
  border-top: 2px solid;
  border-top-color: #4980be;
  box-shadow: 5px 10px #8888880d;
}
.registerSec {
  background-color: #fff;
  border: 1px #00000014 solid;
  border-top: 2px solid;
  border-top-color: #4980be;
  box-shadow: 5px 10px #8888880d;
}
small {
  font-size: 12px;
  font-weight: 700;
  color: #4980be;
}
.loginHead small {
  font-size: 15px;
  font-weight: 700;
  color: #333;
}
.dropdown-item span {
  margin-left: -15px;
  font-size: 15px;
  font-weight: 700;
  color: #4980be;
}
label {
  margin-bottom: 0.5rem;
  float: left;
  font-weight: 500;
}
.form-check-input {
  float: left;
  position: relative;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-label {
  float: left;
  font-weight: 500;
  font-size: 10px;
  margin-top: 4px;
}
.fogtPsswrd {
  float: right;
  font-size: 13px;
  font-weight: 600;
}
.list-group-item {
  position: relative;
  display: block;
  font-size: 14px;
  font-weight: 400;
  padding: 0.75rem 1.25rem;
  text-align: justify;
  background-color: #cccccc00;
  border: 0px solid rgba(0, 0, 0, 0.125);
}
.footerSec {
  margin-top: 100px;
}
.Fterbld {
  font-weight: 600;
  font-size: 16px;
}
.bg-light {
  background-color: #fff !important;
}
.cpyrght {
  font-size: 11px;
}
.wlcmnt {
  margin-top: 40%;
  margin-bottom: 5%;
  font-size: 16px;
  color: #6b6a6a;
  font-weight: 700;
  text-align: justify;
}
.landhd {
  z-index: -1;
  color: #4980be;
  font-size: 50px;
  font-weight: 800;
  line-height: 54px;
  /* text-align: justify; */
}

.alignVertical {
  display: inline-flex;
  align-items: center;
}

.landNte {
  z-index: -1;
  margin-top: 5%;
  /* text-align: justify; */
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #6b6a6a;
}

.landNte:hover {
  color: #333;
}

.link-primary {
  color: #6b6a6a;
  font-weight: 600;
  font-size: 16px;
}

.primary-text {
  font-weight: 600;
  font-size: 1rem;
  text-align: left;
  margin-bottom: 1.5rem;
}

.secondary-text {
  font-weight: 500;
  font-size: 0.8rem;
}

.landImg {
  margin-top: 0%;
}
.landImg a {
  scroll-behavior: smooth;
}
.navSgn {
  margin-top: 12px;
  margin-right: 10%;
}
.landBtn {
  float: left;
  margin-top: 15px;
  margin-bottom: 50px;
}
.btn-primary {
  color: #fff;
  background-color: #4980be;
  border-color: #4980be;
}
.btn-primary:hover {
  color: #fff;
  background-color: #4980be;
  border-color: #4980be;
}
.btn-outline-primary {
  color: #4980be;
  border-color: #4980be;
}
.btn-outline-primary:hover {
  color: #fff;
  border-color: #4980be;
}
.btn-learnmore {
  color: #4980be;
  background-color: #33333317;
  border-color: #f8f9fa;
  font-weight: 500;
}
.btn-learnmore:hover a {
  color: #fff;
  border-color: #f8f9fa;
}
.btn-learnmore:focus {
  color: #fff;
  background-color: #4980be;
  border-color: #f8f9fa;
}
.btn-drop {
  color: #fff;
  background-color: #33333317;
  border-color: #33333317;
}
.btn-drop:hover {
  color: #fff;
  background-color: #33333317;
  border-color: #33333317;
}
.btn-light {
  color: #fff;
  background-color: #33333317;
  border-color: #33333317;
}
.btn-light:hover {
  color: #fff;
  background-color: #4980be;
  border-color: #4980be;
}
.btn-light .svg-inline--fa {
  color: #fff;
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
.dropsec {
  width: 1200px;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #1e2125;
  background-color: #e9ecef;
}
.dropdown-item:focus,
.dropdown-item:hover .fontBtn .svg-inline--fa {
  color: #16181b;
  display: inline-block;
  color: #fff;
}
.dropdown-item:focus,
.dropdown-item:hover .fontBtn {
  color: red;
}
.dropdown-menu {
  position: absolute;
  top: 80%;
  left: 15%;
  padding: 50px;
  z-index: 1200;
  display: none;
  float: left;
  /* min-width: 1000px; */
  padding: 0px 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e9ecef;
  border-radius: 10px;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 1.25rem 0.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  border: 0;
}

.dropdown:hover .dropdown-menu {
  display: block;
}
.fontBtn {
  border-radius: 60px;
  padding-left: 10px;
  color: #fff;
  width: 80px;
  height: 80px;
}
.fontBtn:hover {
  border-radius: 60px;
  padding-left: 10px;
  color: #4980be;
  width: 80px;
  height: 80px;
}
.fontBtn .svg-inline--fa {
  display: inline-block;
  font-size: 48px;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
  color: #49505780;
  margin: 10px 0px 32px 0px;
}
.fontBtn .svg-inline--fa:hover {
  display: inline-block;
  font-size: 48px;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
  color: #fff;
  margin: 10px 0px 32px 0px;
}

.ec-icon:hover {
  cursor: unset;
}

.dropdown-item:focus,
.dropdown-item:hover .fontBtn .svg-inline--fa {
  color: #16181b;
  display: inline-block;
  color: #4980be;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #333;
  text-decoration: none;
  background-color: #fff;
}
.fontfeatrs {
  background-color: #cccccc24;
  margin: 20px 0px 20px 0px;
  padding: 40px 0px 40px 0px;
}
.fontContnr {
  padding-top: 30px;
}
.fontfeatrsSec {
  text-align: center;
}
.fontfeatrsSec.featrBtn {
  text-align: center;
}
.fontfeatrs h2 {
  color: #49505780;
  font-weight: 700;
  font-size: 50px;
  padding-top: 50px;
  text-align: center;
}
.fontfeatrsSec p {
  color: #6b6a6a;
  padding-top: 5px;
  line-height: 25px;
  font-weight: 500;
}
.fontfeatrsSec h6 {
  color: #495057e0;
  padding-top: 15px;
  font-weight: 600;
}
.fontTestmonal {
  background-color: #e1e4e6;
  margin-top: 20px;
}
.fontawesmeCntrFld {
  padding-right: 0px;
  padding-left: 0px;
}

.testimonialRw {
  margin: 20px 0px 20px 0px;
  padding: 40px 0px 40px 0px;
  /* background-color: #cccccc14; */
}
.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  margin-top: 1px;
  overflow: visible;
  vertical-align: -0.125em;
  color: #4980be;
  margin: 15px;
}
label .svg-inline--fa {
  display: inline-block;
  font-size: 45px;
  height: 1em;
  margin-top: 1px;
  overflow: visible;
  vertical-align: -0.125em;
  color: #4980be;
  margin: 15px;
}
label .svg-inline--fa:hover {
  display: inline-block;
  font-size: 45px;
  height: 1em;
  margin-top: 1px;
  overflow: visible;
  vertical-align: -0.125em;
  color: #4980be;
  margin: 15px;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 15px;
}
.card img {
  padding-top: 20px;
}
.testimonialRw h2 {
  font-size: 50px;
  padding-top: 70px;
  font-weight: 1000;
  line-height: 10px;
}
.testimonialRw h6 {
  color: #4980be;
  font-size: 14px;
  padding-top: 100px;
  font-weight: 700;
  line-height: 10px;
}
.testimonialRw p {
  font-size: 12px;
  padding-top: 30px;
  font-weight: 400;
  text-align: justify;
}
.testimonialRw .btn-primary {
  margin-top: 30px;
}
.PricingSec {
  margin: 0px 0px 20px 0px;
}
.PricingSec h2 {
  color: #4980be;
  font-weight: 700;
  text-align: center;
  /* font-size: 40px;
padding-top: 100px;
font-weight: 700;
line-height: 60px;
 */
}
h5 {
  text-align: center;
}
.PricingSec p {
  font-size: 16px;
  padding-top: 12px;
  font-weight: 550;
  text-align: center;
}
.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #4980be;
  color: #fff;
  border-top: 1px solid #4980be;
}
.card-footer.text-muted {
  color: #fff !important;
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
}
.card .list-group-item {
  position: relative;
  display: block;
  text-align: justify;
  font-size: 10px;
  padding: 8px 0px;
  background-color: #fff;
  border: 0px solid rgba(0, 0, 0, 0.125);
}
.list-group-item .svg-inline--fa {
  margin: 0px;
}
.PricingSec .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 15px;
  margin-top: 90px;
}
.pricing .card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 0px solid rgba(0, 0, 0, 0.125);
}
.pricing {
  padding-left: 0px;
  padding-right: 0px;
  background-color: #ffffff;
}
.pricingPrt {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 100px;
  background-color: #ffffff;
}
.pricing button {
  float: right;
}
.card-header h3 {
  float: left;
  font-size: 40px;
  font-weight: bolder;
  padding-left: 20px;
}
.card-header h4 {
  float: right;
  font-size: 60px;
  font-weight: bolder;
}
.card-header p {
  font-size: 30px;
  font-weight: 600;
  color: #6c757d;
  padding-left: 0px;
  padding-top: 40px;
  width: 80%;
}
.text-muted {
  font-weight: 700;
}
.socialBar .svg-inline--fa {
  display: inline-block;
  font-size: 25px;
  height: 1em;
  margin-top: 1px;
  overflow: visible;
  vertical-align: -0.125em;
  color: #333;
  margin: 15px;
}
.socialBar .list-group-item {
  position: relative;
  display: block;
  font-size: 14px;
  font-weight: 400;
  padding: 0.75rem 0px;
  text-align: justify;
  background-color: #cccccc00;
  border: 0px solid rgba(0, 0, 0, 0.125);
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 0px;
  border-left-width: 0;
}
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #333;
}
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link:hover {
  color: #333;
}
.navbar-dark .navbar-nav .nav-link:hover {
  color: #333;
}
.pricingReg {
  background-color: #fff;
}
.PricingReg .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 15px;
  margin-top: 20px;
}
.PricingPlanSec {
  margin: 20px 0px 20px 0px;
  padding: 40px 0px 40px 0px;
}
.PricingPlanSec h2 {
  font-size: 50px;
  padding-top: 100px;
  font-weight: bolder;
  text-align: center;
}
.PricingPlanSec p {
  font-size: 16px;
  padding-top: 12px;
  font-weight: 400;
  text-align: center;
}
.PricingPlanSec .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 15px;
  margin-top: 20px;
}
.PricingPlanSec h4 {
  float: right;
}
.scrollTop {
  position: fixed;
  left: 90%;
  width: 50px;
  bottom: 80px;
  height: 50px;
  color: #fff;
  border-radius: 50px;
}
@-webkit-keyframes scrollTop {
  from {
    -webkit-transform: translateY(2px);
            transform: translateY(2px);
  }
  to {
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
  }
}
@keyframes scrollTop {
  from {
    -webkit-transform: translateY(2px);
            transform: translateY(2px);
  }
  to {
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
  }
}
.scrollbtn {
  position: fixed;
  left: 85%;
  width: 50px;
  bottom: 30px;
  height: 50px;
  color: #fff;
  border-radius: 50px;
  border: 0px #fff solid;
  background-color: #8aa8cc;
}
.scrollbtn:hover {
  position: fixed;
  left: 85%;
  width: 50px;
  bottom: 30px;
  height: 50px;
  color: #fff;
  border-radius: 50px;
  border: 0px #fff solid;
  background-color: #4980be;
}
@media only screen and (max-width: 600px) {
  .scrollbtn {
    position: fixed;
    left: 80%;
    width: 50px;
    bottom: 30px;
    height: 50px;
    color: #fff;
    border-radius: 50px;
    border: 0px #fff solid;
    background-color: #8aa8cc;
  }
  .scrollbtn:hover {
    position: fixed;
    left: 80%;
    width: 50px;
    bottom: 30px;
    height: 50px;
    color: #fff;
    border-radius: 50px;
    border: 0px #fff solid;
    background-color: #4980be;
  }
}
.scrollbtn .svg-inline--fa {
  color: #fff;
  font-size: 30px;
  margin: 0px 0px 0px 0px;
}
.card-header p {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #6c757d;
  padding-left: 0px;
  padding-top: 26px;
  width: 80%;
}
.appPriciing {
  padding-top: 100px;
  margin-bottom: 100px;
  background-color: #cccccc24;
}
.appPriciing .card-header p {
  text-align: justify;
  font-size: 12px;
  font-weight: 500;
  color: #6c757d;
  padding-left: 0;
  padding-top: 0;
  width: 80%;
}
.appPriciing .card-header h3 {
  float: left;
  font-size: 20px;
  font-weight: bolder;
  padding-left: 0px;
}
.dropdown,
.dropleft,
.dropright,
.dropup {
  position: revert;
}
.dropdown-item div {
  padding-top: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #495057;
}
@media only screen and (max-width: 600px) {
  .dropdown-item div {
    padding-top: 5px;
    padding-right: 15px;
    font-weight: 500;
    font-size: 12px;
    color: #495057;
  }
}

.headerImg {
  top: 0px;
  background-image: url(/static/media/headerbg.43bfa3f7.png);
  position: relative;
  background-size: cover;
  width: 193px;
  border-radius: 10px;
}
.layer {
  background-color: rgb(65 107 162 / 84%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.carousel {
  padding-top: 20px;
  max-width: 100%;
  height: 480px;
}
.carousel-fade .carousel-inner {
  position: relative;
  width: 100%;
  overflow: unset;
}

@media only screen and (max-width: 600px) {
  .carousel {
    max-width: 100%;
    height: 400px;
  }
}
@media only screen and (max-width: 600px) {
  .layer {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .d-md-block {
    display: block !important;
  }
}

.layer img {
  padding: 100px 5px 0px 50px;
  width: 75%;
}
@media only screen and (max-width: 600px) {
  .headerImg {
    display: none;
  }
  .layerPric {
    display: none;
  }
}
.layerPric {
  background-color: rgb(65 107 162 / 84%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.layerPric img {
  padding: 45px 0px 0px 50px;
  width: 65%;
}
.figure-img {
  margin-bottom: 0.5rem;
  margin-top: 5%;
  margin-left: 18%;
  line-height: 1;
}
.colr {
  color: #4980be;
  font-weight: 500;
}

.testimg {
  border-radius: 60px;
}
.fontfeatrs h2 {
  color: #49505780;
  font-weight: 700;
  font-size: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
}
/*--slider----*/
.sliderSec {
  padding-top: 100px;
  margin-bottom: 100px;
}
@media only screen and (max-width: 900px) {
  .sliderSec {
    padding-top: 100px;
    margin-bottom: 100px;
  }
}
.sliderDashSec {
  padding-top: 100px;
  margin-bottom: 100px;
  /* background-color: #cccccc24; */
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: -1;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #333;
  text-align: justify;
}

.carousel-caption {
  position: absolute;
  right: 0%;
  bottom: 75%;
  left: 0%;
  top: 0%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #333;
}
carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 5px;
  height: 5px;
  border-radius: 10px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #4980be;
  background-clip: padding-box;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

/* .carousel-item {
  width: 100%;
} */
.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
}

.carousel-item > img {
  width: 100%;
}

.faslide {
  margin-top: -20%;
}
@media only screen and (max-width: 600px) {
  .faslide {
    margin-top: 1%;
  }
}
.faimg {
  margin-top: -20%;
}
.faAppimg {
  margin-top: -20%;
}
@media only screen and (max-width: 900px) {
  .faAppimg {
    margin-top: -5%;
  }
}
@media only screen and (max-width: 600px) {
  .faAppimg {
    margin-top: -5%;
  }
}
@media only screen and (max-width: 900px) {
  .faimg {
    margin-top: 30%;
  }
}
@media only screen and (max-width: 1200px) {
  .faimg {
    margin-top: 0%;
  }
}
@media only screen and (max-width: 600px) {
  .faimg {
    margin-top: 25%;
  }
}
@media only screen and (max-width: 600px) {
  .faslide {
    margin-top: 1%;
  }
}
.appCarousel h1 {
  color: #4980be;
  font-weight: 700;
}
.carousel-caption h3 {
  font-size: 30px;
  padding-top: 30px;
  font-weight: 700;
}
.carousel-caption p {
  font-size: 14px;
  line-height: 25px;
  padding-top: 20px;
  padding-bottom: 30px;
  font-weight: 500;
  text-align: left;
  color: #6b6a6a;
}

/* .appPricng{

  margin: 20px 0px 20px 0px;
  padding: 40px 0px 40px 0px;
} */

.btn-secondary {
  color: #333;
  background-color: #fff;
  border-color: #fff;
}
.btn-secondary:hover {
  color: #333;
  background-color: #fff;
  border-color: #fff;
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #333;
  background-color: #fff;
  border-color: #ffff;
}
.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem #fff;
}
.acrdInfo {
  margin-top: -16px;
}
.cardDetail {
  padding: 100px;
}
.cardPaySec {
  margin-top: 150px;
  padding: 5px;

  box-shadow: 5px 5px #8888881c;
}
.pricingDetail {
  background-color: #4980be;
}
.subscrbBtn {
  width: 100%;
  font-weight: 600;
  background-color: #4980be;
  color: #fff;
  padding: 5px;
  border: 2px #4980be solid;
}
label {
  margin-bottom: 0.5rem;
  float: left;
  font-weight: 500;
  font-size: 13px;
}
.dividertext h6 {
  background-color: #fff;
  /* margin-top: -5px; */
  width: 25%;
  margin-top: -26px;
  margin-left: 34%;
  font-size: 14px;
  color: #33333361;
  margin-bottom: 50px;
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #ced4daed;
  border-radius: 0.25rem;
}
.ConfirmTerms {
  padding-top: 30px;
  color: #96999c;
  font-size: 12px;
}
.subscrplan h4 {
  color: #ffffff99;
  font-size: 22px;
  text-align: center;
  margin-top: 20%;
  line-height: 36px;
}
.prcamnt h3 {
  color: #ffffff99;
  font-size: 50px;
  margin-top: 5%;
}
.prcamnet h3 {
  color: #ffffff99;
  font-size: 60px;
  margin-top: 50px;
  font-weight: 800;
}
.headerImg .svg-inline--fa {
  color: #fff;
  font-size: 50px;
  margin-left: 0%;
  margin-top: 60px;
}
.text-amnt {
  color: #ffffff99;
}
.navbar-brand h2 {
  color: #333;
  margin-left: 76%;
  margin-top: -34%;
  color: #4980be;
  font-size: 35px;
  font-weight: 700;
  line-height: 54px;
  text-align: justify;
}
.StripeElement {
  margin: 15px auto;
  padding: 10px 12px;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
}
.ElementsApp .InputElement.is-invalid {
  color: #333;
}
.InputElement is-invalid Input {
  color: #333;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
.StripeElement {
  border: 1px #ccc solid;
}
.cardlbl {
  margin-top: -10px;
}
.has-error .form-control {
  border-color: #a94442;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
}
.has-error {
  color: #a94442;
  font-weight: 600;
  text-align: center;
  font-size: 14px;
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
  box-shadow: none;
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #4980be;
  border-color: #4980be;
}

.password__show .svg-inline--fa:hover {
  color: #4980be;
  /* margin: -28px 0px 28px 315px; */
  z-index: -1;
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
.password__show .svg-inline--fa {
  color: #4980be;
  /* margin: -28px 0px 28px 315px; */
  z-index: -1;
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
.loginSec .input-group-text {
  display: flex;
  align-items: center;
  padding: 0px;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #ced4daed;
  border-radius: 0.25rem;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  color: #333;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.nav-item:hover .dropdown-menu {
  display: block !important;
  top: 55px;
}

html {
  scroll-behavior: smooth;
}
@media (max-width: 767px) {
  .fixed-top {
    position: absolute !important;
    top: 0px;
  }
}

.fixed-top {
  position: absolute !important;
  top: 0px;
  right: 10px;
  left: 10px;
  z-index: 1030;
}

.fixed-top.fixed-with-server-status {
  top: 50px;
}

.navbar-nav {
  display: flex;
  flex-direction: column;

  margin-bottom: 0;
  list-style: none;
}
.navbar-light .navbar-nav .nav-link {
  color: #6b6a6a;
  font-weight: 500;
}
.input-group-append .btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  /*user-select: none; */
  /*background-color: transparent; */
  border: 1px solid transparent;
  padding: 1px 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.loginSec .svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  margin-top: 1px;
  overflow: visible;
  vertical-align: -0.125em;
  color: #4980be;
  margin: 3px;
}
.loginSec .svg-inline--fa:hover {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  margin-top: 1px;
  overflow: visible;
  vertical-align: -0.125em;
  color: #4980be;
  margin: 5px;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #fff;
  border-color: #fff;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #fff;
  border-color: #fff;
}
a {
  color: #4980be;
  font-weight: 500;
  text-decoration: none;
  background-color: transparent;
}
.Toastify__toast-container {
  z-index: 9999;
  font-weight: 200;
  position: fixed;
  padding: 4px;
  width: 620px;
  box-sizing: border-box;
  color: #fff;
}
text-danger {
  color: #dc3545 !important;
  font-weight: 500;
  font-size: 12px;
}
/*--sidebar----*/
.pro-sidebar {
  color: #adadad;
  height: 100%;
  /* width: 270px;
  min-width: 270px; */
  text-align: left;
  transition: width, left, right, 0.3s;
  position: relative;
  z-index: 1009;
}
.pro-sidebar > .pro-sidebar-inner {
  background: #4980be;
  color: #fff;
  height: 1000px;
  font-weight: 400;
  position: relative;
  z-index: 101;
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
  position: relative;
  background-color: #cddcec54;
}
.pro-menu-item .svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  margin-top: 1px;
  overflow: visible;
  vertical-align: -0.125em;
  color: #fff;
  margin: 15px;
}
.pro-sidebar
  .pro-menu.shaped
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper {
  background-color: #f8f9fa45;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
  color: #d8d8d8;
  background-color: #cddcec54;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:active {
  color: #d8d8d8;
  background-color: #cddcec54;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus {
  color: #d8d8d8;
  background-color: #cddcec54;
}

.pro-sidebar .pro-menu a {
  text-decoration: none;
  color: #fff;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 35px 8px 20px;
  cursor: pointer;
}
.mainNav .navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  margin-left: 12%;
}
.mainNav .navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 5%;
  margin-bottom: 0;
  list-style: none;
}
.mainNav .bg-light {
  border-bottom: 1px #cccccc4f solid;
}
.mainNav .navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px 0px 0px;
  margin-left: -5%;
}
.mainNav .nav-link {
  display: block;
  padding: 0px 30px 0px 0px;
}
.mainNav .svg-inline--fa {
  display: inline-block;
  font-size: 22px;
  height: 1em;
  margin-top: 15px;
  overflow: visible;
  vertical-align: -0.125em;
  color: #49505780;
  margin-right: 0px;
}
.mainNav .svg-inline--fa.user {
  display: inline-block;
  font-size: 22px;
  height: 1em;
  margin-top: 15px;
  overflow: visible;
  vertical-align: -0.125em;
  color: #4980be;
  margin-right: 0px;
}
.logodash {
  width: 30px;
}
.mainNav a {
  color: #4980be;
  font-weight: 500;
  font-size: 12px;
  text-decoration: none;
  background-color: transparent;
}
.pro-sidebar .pro-menu .pro-menu-item {
  font-size: 13px;
}

.block span {
  display: inline-block;
}

.block img {
  padding-top: 15px;
  border-radius: 60px;
}

.mainNav .svg-inline--fa {
  display: inline-block;
  font-size: 14px;
  height: 1em;
  margin-top: 15px;
  overflow: visible;
  vertical-align: -0.125em;
  color: #49505780;
  margin-right: 0px;
}
.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .dropdown-item span {
  display: inline-block;
  font-weight: 500;
}
.userimg {
  width: 40px;
  border-radius: 40px;
  margin: -35px 0px 0px 10px;
}
.ProfileAccount h2 {
  text-align: justify;
  color: #6b6a6a;
  margin-top: 50px;
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 50px;
}
.profylSec {
  border: 1px #cccccc4f solid;
  padding: 50px;
}
.profylImg img {
  border-radius: 100px;
}
@media only screen and (max-width: 600px) {
  .profylImg img {
    width: 50px;
  }
  .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 2.9rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 5px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .overlayProfile {
    position: relative;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.3s ease;
    background-color: red;
  }

  .icon .svg-inline--fa {
    color: #4980be;
    top: 8%;
    display: inline-block;
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
  }
}
.card img {
  padding-top: 0px;
}
.profylTitle {
  font-size: 12px;
  text-align: justify;
  font-weight: 600;
}
.profylDetail {
  padding: 20px;
}
.editIcon {
  float: right;
}
.editIcon.svg-inline--fa {
  display: inline-block;
  font-size: 20px;
  height: 1em;
  margin-top: 5px;
  overflow: visible;
  vertical-align: -0.125em;
  color: #6c757d;
}
svg {
  overflow: hidden;
  vertical-align: middle;
  cursor: pointer;
}
.editIcon.svg-inline--fa:hover {
  display: inline-block;
  font-size: 20px;
  height: 1em;
  margin-top: 5px;
  overflow: visible;
  vertical-align: -0.125em;
  color: #6c757d;
}
.editImgIcon.svg-inline--fa {
  display: inline-block;
  font-size: 20px;
  height: 1em;
  margin-top: 1px;
  overflow: visible;
  vertical-align: -0.125em;
  color: #4980be;
  margin: -8px 15px 15px 100px;
}
.editImgIcon.svg-inline--fa:hover {
  display: inline-block;
  font-size: 20px;
  height: 1em;
  margin-top: 1px;
  overflow: visible;
  vertical-align: -0.125em;
  color: #4980be;
  margin: -8px 15px 15px 100px;
}
.editlogoIcon.svg-inline--fa {
  display: inline-block;
  font-size: 20px;
  height: 1em;
  margin-top: 1px;
  overflow: visible;
  vertical-align: -0.125em;
  color: #4980be;
  margin: -8px 15px 15px 150px;
}
.editlogoIcon.svg-inline--fa:hover {
  display: inline-block;
  font-size: 20px;
  height: 1em;
  margin-top: 1px;
  overflow: visible;
  vertical-align: -0.125em;
  color: #4980be;
  margin: -8px 15px 15px 150px;
}
.profylImg h2 {
  font-weight: 800;
}
.btnSave {
  float: right;
}
.profylImg label {
  margin-bottom: 0.5rem;
  float: none;
  font-weight: 500;
}
.passdSec .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0px;
}
.card-header {
  height: 190px;
  text-align: left;
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.passdSec .card .form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.ChngePsswd {
  border-radius: 25px;
  float: left;
  font-size: 12px;
}
.twofactr {
  border-radius: 25px;
  float: left;
  font-size: 12px;
}
.passdSec .card-text {
  font-size: 14px;
  line-height: 28px;
}
.password_security .input-group {
  position: relative;
  display: flex;
  flex-wrap: unset;
  align-items: stretch;
  width: 100%;
}
.password_security .border {
  height: 32px;
  border: 1px solid #dee2e6 !important;
}
.password_security .border-left-0 {
  height: 33px;
  border-left: 0 !important;
}
.password_security .border-right-0 {
  height: 33px;
  border: 1px solid #dee2e6 !important;
}
.password__secure .svg-inline--fa {
  color: #4980be;
  margin: 7px 13px 10px 20px;
  z-index: -1;
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
.password__secure .svg-inline--fa:hover {
  color: #4980be;
  margin: 7px 13px 10px 20px;
  z-index: -1;
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
.password__secureright .svg-inline--fa {
  color: #6c757d;
  margin: 7px 13px 10px 20px;
  z-index: -1;
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
.password__secureright .svg-inline--fa:hover {
  color: #6c757d;
  margin: 7px 13px 10px 20px;
  z-index: -1;
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
.trash {
  color: #721c24;
  font-size: 20px;
}
.trash:hover {
  color: #721c24;
  font-size: 20px;
}
.addresssec h3 {
  font-size: 12px;
  color: #6b6a6a;
  line-height: 17px;
  font-weight: 800;
}
.addresssec h5 {
  font-size: 12px;
  color: #6b6a6a;
  line-height: 17px;
}
.seialtext h5 {
  font-size: 12px;
  color: #6b6a6a;
  line-height: 17px;
  margin-top: 10px;
}
.trashdropdown .btn-primary {
  color: #fff;
  background-color: #fff;
  border-color: #fff;
}
.trashdropdown .btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #fff;
  border-color: #fff;
}
.trashdropdown .btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgb(244 245 247 / 50%);
}
.deletenote {
  padding: 20px;
}
.deletenote .btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.deletenote .btn-outline-primary {
  color: #4980be;
  border-color: #4980be;
  font-size: 10px;
}
.deletenote .btn-outline-danger {
  font-size: 10px;
}
.deletenote p {
  font-size: 12px;
  color: #6b6a6a;
  line-height: 20px;
  font-weight: 500;
}
.subsInfo p {
  text-align: justify;
}
.subshead h5 {
  font-size: 14px;
  font-weight: 600;
  text-align: justify;
}
.subshead p {
  font-size: 12px;
  font-weight: 500;
  text-align: justify;
}
.subsInfo h6 {
  color: #4980be;
  font-size: 12px;
  font-weight: 500;
  text-align: justify;
}
.subsInfo h5 {
  color: red;
  font-size: 12px;
  font-weight: 500;
  text-align: justify;
}
.secHead h5 {
  font-weight: 600;
  font-size: 14px;
}
.secp h5 {
  font-weight: 400;
  font-size: 12px;
}
.secp .svg-inline--fa {
  display: inline-block;
  font-size: 20px;
  height: 1em;
  margin-top: -1px;
  overflow: visible;
  vertical-align: -0.125em;
  color: #333;
}
.cloudtext {
  color: #394ca5;
  font-size: 14px;
  text-transform: capitalize;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
  color: #d8d8d8;
  background-color: #cddcec54;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item .active:active {
  color: #d8d8d8;
  background-color: #cddcec54;
  padding: 20px;
}
.pro-menu-item li {
  color: blue;
}
.form-inline .btn-primary {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0px 0px 0px 0px;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-signup {
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
  background-color: #4980be;
  border: 1px solid #4980be;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-signup:hover {
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
  background-color: #4980be;
  border: 1px solid #4980be;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #333;
  background-color: #fff;
  border-color: #fff;
}
.form-inline .dropdown,
.dropleft,
.dropright,
.dropup {
  position: relative;
}
.form-inline .dropdown-menu {
  min-width: 10rem;
  left: -100px;
  top: 140%;
}
.form-inline .dropdown-item {
  display: block;
  width: 100%;
  padding: 4px 8px 4px 16px;
  font-size: 10px;
  clear: both;
  font-weight: 500;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  border: 0;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}
.spanbell {
  margin: 0px;
  font-size: 12px;
}
.form-inline .btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #fff;
}
.form-inline .dropdown-toggle::after {
  display: none;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.block p {
  font-size: 8px;
  font-weight: bolder;
  margin-top: 2%;
}
.profylDetail .card-text {
  font-size: 12px;
  text-align: justify;
}
.PhoneInputInput {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  flex: 1 1;
  min-width: 0;
}
.image {
  display: block;
  width: 100%;
  height: auto;
}
.icon .svg-inline--fa:hover {
  color: #4980be;
  display: inline-block;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}

.overlayProfile {
  position: unset;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.3s ease;
  background-color: red;
}

.profylImg:hover .overlayProfile {
  opacity: 1;
}

.icon {
  color: white;
  font-size: 50px;
  position: absolute;
  top: 20%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.profylText {
  font-weight: 500;
}
.alert {
  width: 600px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 26%);
  border-radius: 0px;
  margin-bottom: 0px;
}
.alert-danger {
  color: #333;
  background-color: #fde7e9;
  border-color: #fde7e9;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 26%);
  padding: 10px 0px 0px 0px;
}
.alert-primary {
  color: #333;
  background-color: #cce5ff;
  border-color: #b8daff;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 26%);
  padding: 10px 0px 0px 0px;
}
.alert-heading {
  color: inherit;
  font-size: 12px;
}
.alert p {
  font-size: 10px;
  font-weight: 500;
}
.alert a {
  font-size: 10px;
  font-weight: 500;
}
.close {
  float: right;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
  font-size: 12px;
  float: right;
}
.btn-outline-secondary:hover {
  color: #6c757d;
  border-color: #6c757d;
  font-size: 12px;
}
.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #3286da;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs a {
  color: #333;
  font-weight: 500;
  text-decoration: none;
  background-color: transparent;
}
.nav-tabs .svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  margin-top: 1px;
  overflow: visible;
  vertical-align: -0.125em;
  color: #6c757d;
  margin: 0px;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link .svg-inline--fa.active {
  color: #3286da;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.editcard .card-header {
  text-align: left;
  padding: 30px;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.Toastify__toast-body {
  margin: auto 0;
  flex: 1 1 auto;
  padding: 65px;
}
.WelcomeNote h3 {
  font-weight: 900;
}
.WelcomeNote p {
  font-weight: 400;
  line-height: 25px;
  color: #17395fed;
}
.input-group input {
  margin: 0;
  font-family: inherit;
  width: 100%;
  font-size: 12px;
  line-height: inherit;
}
.upload .svg-inline--fa {
  color: #4980be;
  display: inline-block;
  font-size: 20px;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
.upload .svg-inline--fa:hover {
  color: #4980be;
  display: inline-block;
  font-size: 20px;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
.upload {
  margin-top: 150px;
}
.upload button {
  border-radius: 100px;
  background-color: #e7edf3;
  border: #fff;
}
.card-body h5 {
  text-align: left;
  font-weight: 700;
  font-size: 15px;
}
.card-body p {
  text-align: left;
  font-size: 12px;
}
.switch {
  float: left;
  position: relative;
  overflow: hidden;
}
.saveBtn {
  margin-left: 80%;
}
.notFoundHead {
  font-weight: 900;
  font-size: 200px;
  color: #ccc;
  margin-top: 50px;
  text-align: center;
}
.notFoundHead .svg-inline--fa {
  color: #ccc;
}
.pageFoundHead h3 {
  font-size: 25px;
  font-weight: 500;
  color: #3333338f;
  line-height: 40px;
  text-align: justify;
}
.btn-home {
  background-color: #5f96d4;
  padding: 20px;
  width: 30%;
  color: #fff;
}
.btn-home a {
  color: #fff;
}
.slide {
  white-space: pre-wrap;
}
@media only screen and (max-width: 600px) {
  .slide {
    white-space: pre-wrap;
    margin-bottom: 100px;
  }
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transition: -webkit-transform 0s ease-in-out;
  transition: transform 0s ease-in-out;
  transition: transform 0s ease-in-out, -webkit-transform 0s ease-in-out;
}
/* slider */
.carousel-control-next,
.carousel-control-prev {
  background-color: #4980be;
  position: absolute;
  top: 375px;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 0;
  color: #fff;
  text-align: center;
  /* background: 0 0; */
  border-radius: 20px;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
.carousel-control-next:hover,
.carousel-control-prev:hover {
  background-color: #6fa7e7;
  position: absolute;
  top: 375px;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 0;
  color: #fff;
  text-align: center;
  /* background: 0 0; */
  border-radius: 20px;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

/*----media queries for slider view-----*/

@media only screen and (max-width: 900px) {
  .carousel-control-next,
  .carousel-control-prev {
    background-color: #4980be;
    position: absolute;
    top: 400px;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    color: #fff;
    text-align: center;
    /* background: 0 0; */
    border-radius: 20px;
    opacity: 0.5;
    transition: opacity 0.15s ease;
  }
  .carousel-control-next:hover,
  .carousel-control-prev:hover {
    background-color: #6fa7e7;
    position: absolute;
    top: 400px;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    color: #fff;
    text-align: center;
    /* background: 0 0; */
    border-radius: 20px;
    opacity: 0.5;
    transition: opacity 0.15s ease;
  }
}
@media only screen and (max-width: 1200px) {
  .carousel-control-next,
  .carousel-control-prev {
    background-color: #4980be;
    position: absolute;
    top: 400px;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    color: #fff;
    text-align: center;
    /* background: 0 0; */
    border-radius: 20px;
    opacity: 0.5;
    transition: opacity 0.15s ease;
  }
  .carousel-control-next:hover,
  .carousel-control-prev:hover {
    background-color: #6fa7e7;
    position: absolute;
    top: 400px;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    color: #fff;
    text-align: center;
    /* background: 0 0; */
    border-radius: 20px;
    opacity: 0.5;
    transition: opacity 0.15s ease;
  }
}
@media only screen and (max-width: 600px) {
  .carousel-control-next,
  .carousel-control-prev {
    background-color: #4980be;
    position: absolute;
    top: 410px;
    bottom: 0;
    margin-right: 5px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    color: #fff;
    text-align: center;
    /* background: 0 0; */
    border-radius: 20px;
    opacity: 0.5;
    transition: opacity 0.15s ease;
  }
  .carousel-control-next:hover,
  .carousel-control-prev:hover {
    background-color: #6fa7e7;
    position: absolute;
    top: 410px;
    bottom: 0;
    margin-right: 5px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    color: #fff;
    text-align: center;
    /* background: 0 0; */
    border-radius: 20px;
    opacity: 0.5;
    transition: opacity 0.15s ease;
  }
}
/*----media quieries for slider view ends---*/
.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}
.carousel-control-next {
  right: 410px;
}
@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 900px) {
}
@media only screen and (max-width: 1200px) {
}
.sr-only {
  display: none;
}
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 14%;
  /* left: -30%; */
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 7px;
  height: 7px;
  border-radius: 10px;
  /* margin-right: 3px;
  margin-left: 3px; */
  text-indent: -999px;
  cursor: pointer;
  background-color: #4980be;
  background-clip: padding-box;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media only screen and (max-width: 1200px) {
  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 10%;
    left: 0%;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
  }
}
@media only screen and (max-width: 900px) {
  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 10%;
    left: 0%;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
  }
}
@media only screen and (max-width: 600px) {
  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: -40%;
    left: 0%;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
  }
}
.carousel-control-next {
  right: 82%;
}
/* Extra small devices (phones, 600px and down) */

.pricingSub {
  border: 1px #4980be solid;
  border-radius: 10px;
  font-size: 12px;
  padding: 10px;
  font-weight: 300;
  color: #4980be;
}
.pricingSub:hover {
  border: 1px #4980be solid;
  border-radius: 10px;
  font-size: 12px;
  padding: 10px;
  font-weight: 300;
  color: #4980be;
}
.carousel-indicators .active {
  opacity: 1;
}
.sliderDashSec .carousel-control-next {
  background-color: #4980be;
  position: absolute;
  top: 320px;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 0;
  color: #fff;
  text-align: center;
  /* background: 0 0; */
  border-radius: 20px;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
.sliderDashSec .carousel-control-prev {
  background-color: #4980be;
  position: absolute;
  top: 320px;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 0;
  color: #fff;
  text-align: center;
  /* background: 0 0; */
  border-radius: 20px;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
.sliderDashSec .carousel-control-next:hover {
  background-color: #6fa7e7;
  position: absolute;
  top: 320px;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 0;
  color: #fff;
  text-align: center;
  /* background: 0 0; */
  border-radius: 20px;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
.sliderDashSec .carousel-control-prev:hover {
  background-color: #6fa7e7;
  position: absolute;
  top: 320px;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 0;
  color: #fff;
  text-align: center;
  /* background: 0 0; */
  border-radius: 20px;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
.sliderDashSec.carousel-control-next:hover,
.sliderDashSec .carousel-control-prev:hover {
  background-color: #6fa7e7;
  position: absolute;
  top: 320px;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 0;
  color: #fff;
  text-align: center;
  /* background: 0 0; */
  border-radius: 20px;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media only screen and (max-width: 600px) {
  .carousel-control-next {
    right: 0px;
  }
}
@media only screen and (max-width: 900px) {
  .carousel-control-next {
    right: 0px;
  }
}
@media only screen and (max-width: 1200px) {
  .carousel-control-next {
    right: 0px;
  }
}
/*media queries for desk slider--*/
@media only screen and (max-width: 600px) {
  .sliderDashSec .carousel-control-next {
    background-color: #4980be;
    position: absolute;
    top: 420px;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    color: #fff;
    text-align: center;
    /* background: 0 0; */
    border-radius: 20px;
    opacity: 0.5;
    transition: opacity 0.15s ease;
  }
  .sliderDashSec .carousel-control-prev {
    background-color: #4980be;
    position: absolute;
    top: 420px;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    color: #fff;
    text-align: center;
    /* background: 0 0; */
    border-radius: 20px;
    opacity: 0.5;
    transition: opacity 0.15s ease;
  }
  .sliderDashSec .carousel-control-next:hover {
    background-color: #6fa7e7;
    position: absolute;
    top: 420px;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    color: #fff;
    text-align: center;
    /* background: 0 0; */
    border-radius: 20px;
    opacity: 0.5;
    transition: opacity 0.15s ease;
  }
  .sliderDashSec .carousel-control-prev:hover {
    background-color: #6fa7e7;
    position: absolute;
    top: 420px;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    color: #fff;
    text-align: center;
    /* background: 0 0; */
    border-radius: 20px;
    opacity: 0.5;
    transition: opacity 0.15s ease;
  }
}
@media only screen and (max-width: 900px) {
  .sliderDashSec .carousel-control-next {
    background-color: #4980be;
    position: absolute;
    top: 420px;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    color: #fff;
    text-align: center;
    /* background: 0 0; */
    border-radius: 20px;
    opacity: 0.5;
    transition: opacity 0.15s ease;
  }
  .sliderDashSec .carousel-control-prev {
    background-color: #4980be;
    position: absolute;
    top: 420px;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    color: #fff;
    text-align: center;
    /* background: 0 0; */
    border-radius: 20px;
    opacity: 0.5;
    transition: opacity 0.15s ease;
  }
  .sliderDashSec .carousel-control-next:hover {
    background-color: #6fa7e7;
    position: absolute;
    top: 420px;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    color: #fff;
    text-align: center;
    /* background: 0 0; */
    border-radius: 20px;
    opacity: 0.5;
    transition: opacity 0.15s ease;
  }
  .sliderDashSec .carousel-control-prev:hover {
    background-color: #6fa7e7;
    position: absolute;
    top: 420px;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    color: #fff;
    text-align: center;
    /* background: 0 0; */
    border-radius: 20px;
    opacity: 0.5;
    transition: opacity 0.15s ease;
  }
}
@media only screen and (max-width: 1200px) {
  .sliderDashSec .carousel-control-next {
    background-color: #4980be;
    position: absolute;
    top: 420px;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    color: #fff;
    text-align: center;
    /* background: 0 0; */
    border-radius: 20px;
    opacity: 0.5;
    transition: opacity 0.15s ease;
  }
  .sliderDashSec .carousel-control-prev {
    background-color: #4980be;
    position: absolute;
    top: 420px;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    color: #fff;
    text-align: center;
    /* background: 0 0; */
    border-radius: 20px;
    opacity: 0.5;
    transition: opacity 0.15s ease;
  }
  .sliderDashSec .carousel-control-next:hover {
    background-color: #6fa7e7;
    position: absolute;
    top: 420px;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    color: #fff;
    text-align: center;
    /* background: 0 0; */
    border-radius: 20px;
    opacity: 0.5;
    transition: opacity 0.15s ease;
  }
  .sliderDashSec .carousel-control-prev:hover {
    background-color: #6fa7e7;
    position: absolute;
    top: 420px;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    color: #fff;
    text-align: center;
    /* background: 0 0; */
    border-radius: 20px;
    opacity: 0.5;
    transition: opacity 0.15s ease;
  }
}
/* media queries for desk slider ends---*/

.sliderDashSec .carousel-indicators .active {
  opacity: 1;
}

.sliderDashSec .carousel-control-prev {
  left: 0px;
}
.sliderDashSec .carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 25%;
  /* left: -45%; */
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
}

@media only screen and (max-width: 1200px) {
  .sliderDashSec .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 5%;
    left: 0%;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
  }
}
@media only screen and (max-width: 900px) {
  .sliderDashSec .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 5%;
    left: 0%;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
  }
}
@media only screen and (max-width: 600px) {
  .sliderDashSec .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: -45%;
    left: 0%;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
  }
}

.sliderDashSec .carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 7px;
  height: 7px;
  border-radius: 10px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #4980be;
  background-clip: padding-box;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
/* .featrBtn .svg-inline--fa.fa-w-16 {
  width: 100px;
  height:100px;
}
.featrBtn .svg-inline--fa.fa-w-18 {
width: 100px;
height:100px;
}
.featrBtn .svg-inline--fa.fa-w-18 {
width: 100px;
height:100px;
} */
.navbar-collapse {
  z-index: 1;
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
  background-color: #ffff;
}
[data-aos^="zoom"][data-aos^="zoom"].aos-animate {
  opacity: 1;
  /* z-index:initial; */
  -webkit-transform: none;
          transform: none;
}
/* .featrBtn {
   text-align: center;
  background-color: red;
  border-radius: 20px;

}  */
.featrBtn {
  width: 100px;
  height: 100px;
  display: flex;
  text-align: center;
  background-color: #fff;
  margin: auto;
  border-radius: 100px;
}
.featrBtn .svg-inline--fa {
  cursor: auto;
  display: inline-block;
  font-size: 60px;
  overflow: visible;
  vertical-align: -0.125em;
  color: #49505780;
  margin: auto;
}
/* @media only screen and (max-width: 600px) {
  .featrBtn .svg-inline--fa {
 

    border-radius: 45px;
  display: inline-block;
    font-size: 80px;
  padding:0px;
    overflow: visible;
    vertical-align: -0.125em;
    color: #49505780;
    margin: 10px 0px 32px 0px;
  }
} */
.feedback a:hover {
  color: #fff;
}
.feedback a {
  color: #fff;
}
.btnSignup {
  background-color: #4980be;
  color: #fff;
}

.mobl .navbar {
  display: none;
}
@media only screen and (max-width: 600px) {
  #root {
    width: 100%;
    display: flex;
  }
}
@media only screen and (max-width: 1600px) {
  .mobl .navbar {
    display: none;
  }
}
@media only screen and (max-width: 1200px) {
  .desktopNavbar .navbar {
    display: block;
  }
  .mobl .navbar {
    display: none;
  }
}

@media only screen and (max-width: 900px) {
  .desktopNavbar .navbar {
    display: none;
  }
  .mobl .navbar {
    display: block;
  }
}
@media only screen and (max-width: 600px) {
  .desktopNavbar .navbar {
    display: none;
  }
  .mobl .navbar {
    display: block;

    margin-right: -5px;
  }
  .mainCollapse.navbar-collapse {
    z-index: 1;
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    background-color: #ffff;
    box-shadow: 2px 0px 2px 2px #33333321;
  }
  .bg-dark {
    border-bottom: 1px #fff solid;
  }
}

.dropdown-col {
  padding: 0px;
}

.mobl .navbar .dropdown-item {
  display: block;
  width: 100%;
  padding: 10px;
  clear: both;
  font-size: 12px;
  font-weight: 500;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  border: 0;
}
.mobl .navbar-nav {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  list-style: none;
}
.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
@media only screen and (max-width: 900px) {
  .flex-container {
    margin-top: -85px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
@media only screen and (max-width: 600px) {
  .flex-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}
.flex-container div {
  cursor: pointer;
  /* margin: 30px 20px 20px 20px; */
  margin: 10px 10px 10px 10px;
}
@media only screen and (max-width: 1400px) {
  .flex-container div {
    cursor: pointer;
    margin: 5px 5px 5px 5px;
  }
}
@media only screen and (max-width: 1200px) {
  .flex-container div {
    cursor: pointer;
    margin: 5px 5px 5px 5px;
  }
}
@media only screen and (max-width: 900px) {
  .flex-container div {
    cursor: pointer;
    margin: 10px 10px 10px 10px;
  }
}
@media only screen and (max-width: 800px) {
  .flex-container div {
    cursor: pointer;
    margin: 10px 10px 10px 10px;
  }
}
@media only screen and (max-width: 600px) {
  .flex-container div {
    cursor: pointer;
    margin: 5px 5px 5px 5px;
  }
}
.app-store .text {
  font-size: 9px;
  left: 48px;
  color: #ccc;
}
.text:hover {
  color: #ccc;
}
.app-store .text,
.app-store svg {
  top: 50%;
  /* -webkit-transform: translate(0,-50%);
    transform: translate(0,-50%); */
}
.app-store a {
  color: red;
}
.app-store a:hover {
  color: #ccc;
}

.app-store .text,
.app-store svg {
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.app-store svg {
  width: 24px;
  left: 12px;
}
.app-store .text {
  font-size: 9px;
  display: flex;
  left: 48px;
  flex-direction: column;
  align-content: flex-end;
  flex-wrap: wrap;
}
.app-store {
  -webkit-box-align: center;
  display: -webkit-box;
  align-items: center;
  border: 2px solid #5e5e5e;
  border-radius: 8px;
  display: inline-block;
  height: 48px;
  line-height: 1;
  text-align: left;
  width: 150px;
}
.app-store .app-name {
  font-size: 11px;
  letter-spacing: 0.3px;
}
.app-list {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
.app-store {
  position: relative;
}

.app-store span:hover {
  color: red;
}
.app-store span {
  margin-top: -10%;
}
.app-store .svg-inline--fa.fa-w-12 {
  font-size: 25px;
  width: 0.75em;
  margin-top: -10px;
  font-size: 20px;
}

.flex-boss {
  display: flex;
  border: 2px #9a8c8c solid;
  padding: -10px;
  border-radius: 10px;
}
.flex-boss svg {
  font-size: 20px;
  margin: 2px 9px 0px 10px;
  font-size: 30px;
  color: #9a8c8c;
}
.flex-boss svg:hover {
  font-size: 20px;
  margin: 2px 9px 0px 10px;
  font-size: 30px;
  color: #333;
}
.flex-child {
  color: #9a8c8c;
  flex: 1 1;
  font-size: 10px;
  width: 120px;
  padding-left: 40px;
}
.flex-child:hover {
  color: #333;
  flex: 1 1;
  font-size: 10px;
  width: 120px;
  padding-left: 40px;
}
.flex-child svg {
  font-size: 30px;
  color: #9a8c8c;
}

.flex-child1 {
  color: #9a8c8c;
  margin-right: 0px;
  width: 10px;
}
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end,
.carousel-fade .carousel-item.active {
  z-index: 0;
  opacity: 1;
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 30%;
  margin-top: 0.125rem;
}
.videobanner {
  width: 100%;
  margin-bottom: 40px;
  background-image: url(/static/media/Banner.f4b56a41.png);
  min-width: 100%;
  max-width: none;
  min-height: 650px;
  background-size: cover;
  background-position-x: center;
  background-repeat: no-repeat;
  width: auto;
}

.videobanner h1 {
  margin: 15% 0% 2% 0%;
  z-index: -1;
  color: #4980be;
  font-size: 40px;
  font-weight: 800;
  line-height: 54px;
}
.videobanner h4 {
  margin: 0% 0% 2% 0%;
  z-index: -1;
  color: #21252994;
  font-size: 20px;
  font-weight: 800;
  line-height: 30px;
}
.videobanner h5 {
  margin: 0% 0% 2% 0%;
  z-index: -1;
  color: #4980be;
  font-size: 20px;
  font-weight: 800;
  line-height: 30px;
  cursor: pointer;
  text-align: justify;
}
.videobanner h4:hover {
  margin: 0% 0% 2% 0%;
  z-index: -1;
  color: #333;
  font-size: 20px;
  font-weight: 800;
  line-height: 30px;
}
.videoplay .svg-inline--fa {
  display: inline-block;
  font-size: 35px;
  height: 1em;
  margin-top: 1px;
  overflow: visible;
  vertical-align: -0.125rem;
  color: #4980be;
  margin: 6px 0px 0px 20px;
}
.videoplay h4:hover {
  color: #333;
}
@media only screen and (max-width: 760px) {
  .videobanner {
    width: 100%;
    margin-bottom: 0px;
    background-image: none;
    height: 300px;
  }
}
.showonMObile {
  width: 100%;
  margin-top: -100%;
  margin-bottom: -50%;
  background-image: url(/static/media/Banner.f4b56a41.png);
  min-width: 100%;
  max-width: none;
  min-height: 650px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: auto;
}
@media only screen and (min-width: 768px) {
  .showonMObile {
    display: none;
  }
}
.list-group-horizontal p {
  font-size: 12px;
}
.list-group-horizontal a {
  font-size: 12px;
  color: #4980be;
}
.SubscribeIcon .svg-inline--fa,
.SubscribeIcon .svg-inline--fa:hover {
  display: inline-block;
  font-size: 40px;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
.SubscribeIcon .svg-inline--fa {
  color: #474242;
}
.SubscribeSec .card-header p {
  text-align: justify;
  font-size: 12px;
  font-weight: 500;
  color: #6c757d;
  padding-left: 0;
  padding-top: 0;
  width: 80%;
}
.PricingSec .card-header {
  text-align: left;
  padding: 15px;
  height: 180px;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.PricingSec .card-body {
  flex: 1 1 auto;
  height: 320px;
  padding: 5px;
  color: #6b6a6a;
  font-weight: 400;
  font-size: 14px;
}
.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform 0.8s ease-in-out;
  transition: transform 0.8s ease-in-out;
  transition: transform 0.8s ease-in-out, -webkit-transform 0.8s ease-in-out;
}
/* susbcription nav tabs---*/
.SubscriptionTabs .flex-column {
  flex-direction: row !important;
}
.SubscriptionTabs .flex-container {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SubscriptionTabs .flex-item {
  padding: 5px;
  width: 155px;
  height: 20px;
  margin: 10px;
  line-height: 20px;

  font-weight: bold;
  font-size: 15px;
  text-align: center;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #4980be;
  border: 1px #4980be solid;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%), 0 2px 10px 0 rgb(0 0 0 / 10%);
  width: 120%;
}
.nav-pills .nav-link {
  color: #333;
  width: 120%;
  font-weight: 500;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%), 0 2px 10px 0 rgb(0 0 0 / 10%);
}
.userImg img {
  width: 60px;
  height: 60px;
  -webkit-border-radius: 60px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 60px;
  -moz-background-clip: padding;
  border-radius: 60px;
  background-clip: padding-box;
  margin: 7px 0 0 5px;
  float: left;
  background-size: cover;
  background-position: center center;
  margin-top: 30px;
}

.card-body p {
  font-size: 12px;
  padding-top: 30px;
  font-weight: 400;
  text-align: justify;
  line-height: 20px;
}

.feedbackHeader {
  margin-top: 150px;
  z-index: -1;
  color: #4980be;
  font-size: 40px;
  font-weight: 800;
  line-height: 54px;
  margin-bottom: 30px;
}

.feedbackProductCard {
  width: 37rem;
}

.feedbackProductItem {
  font-size: 13px;
  text-align: center;
}
.css-dqr9h-MuiRating-label {
  font-size: 60px;
}

.feedbackItem {
  margin-top: 10px;
}

.selectedProduct {
  font-size: 13px;
  text-align: center;
  background-color: #4980be;
  color: white;
  border-radius: 10px;
}

.feedbackProductItem:hover {
  cursor: pointer;
  font-size: 13px;
  text-align: center;
  background-color: #4980be;
  color: white;
  border-radius: 10px;
}

.form-check-label {
  font-size: 13px;
}

.feedbackText {
  text-align: center;
}

.feedbackContainer {
  max-width: 30%;
  margin: 0 auto;
  margin-bottom: 20px;
}

@media only screen and (max-width: 1500px) {
  .feedbackContainer {
    max-width: 50%;
  }
}

@media only screen and (max-width: 900px) {
  .feedbackContainer {
    max-width: 90%;
  }
}

@media only screen and (max-width: 500px) {
  .feedbackProductCard {
    max-width: 350px;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 600px) {
  .feedbackProductCard {
    max-width: 900px;
    margin: 0 auto;
  }
}

.css-ykqdxu {
  font-size: 45px;
}

.faq-title {
  color: #4980be;
  font-size: 50px;
}

strong {
  font-weight: bold;
  color: #000;
}

.numberIcon {
  font-size: 22px;
  color: #4980be;
}

.stepsIcons {
  display: inline-block;
  font-size: 60px;
  overflow: visible;
  vertical-align: -0.125em;
  color: #4980be;
  margin: auto;
}

/* .stepItem {
  border-bottom: #495057e0 1px solid;
  margin-bottom: 50px;
} */

.overview {
  background-color: #fff;
}

.stepsDesc {
  font-size: 16px;
  color: #6b6a6a;
  font-weight: 700;
}

.overviewVid {
  width: 70%;
  height: 50vh;
  margin: 0 auto;
}

@media only screen and (max-width: 800px) {
  .overviewVid {
    width: 100%;
    height: 50vh;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 500px) {
  .landhd {
    font-size: 40px;
  }
}

@media only screen and (max-width: 500px) {
  .stepsDivider {
    display: none;
  }
}

.mailLink {
  color: #4980be;
}

.elitecontrol-shutdown-link {
  color: #4980be;
  font-size: 1.3rem;
  font-weight: 700;
}

.elitecontrol-shutdown-link:hover {
  color: #376aa5;
}

.elitecontrol-shutdown-link span.important {
  background-color: #4980be;
  color: #fff;
  border-radius: 15px;
  padding: 5px 15px;
  margin-right: 15px;
}

.elitecontrol-shutdown-link span.important:hover {
  background-color: #376aa5;
}

.appStore {
  font-size: 30px;
  color: #4980be;
  /* color: #4980be; */
}

.appStore:hover {
  color: #376aa5;
}

.mailLink:hover {
  color: #376aa5;
}

.iconLink:hover {
  color: #376aa5;
  font-size: 65px;
}

.faqContent {
  font-weight: 100;
}

.accordion-button:not(.collapsed) {
  color: #4980be;
}

.nonClickable:hover {
  cursor: default;
}

.upgradeButton:hover {
  background-color: #376aa5;
}

.contactSupport {
  text-align: center;
}
.banner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  background-color: #4980be;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.bannerText {
  color: white;
  font-size: 18px;
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 800px) {
  .bannerText {
    font-size: 14px;
  }
}

.show-server-status {
  margin-top: 50px;
}

